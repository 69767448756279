/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';

import { AudiVideoServiceInterfaceV1 } from '@oneaudi/video-service';
import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';

import { ContentContextProvider, FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { LayerManagerV27 } from '@volkswagen-onehub/layer-manager';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import { OneGraphQueryServiceV1 } from '@oneaudi/onegraph-query-service';
import { NumberFormatterService } from '@oneaudi/number-formatter-service';
import App from './FeatureApp';
import ContextProvider from './Context';
import { isTrackingDisabled } from './utils/debugging';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 'audi-video-service': AudiVideoServiceInterfaceV1 | undefined;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1 | undefined;
  readonly 's2:serialized-state-manager': SerializedStateManagerV1 | undefined;
  readonly 'layer-manager': LayerManagerV27;
  readonly 'audi-render-mode-service'?: RenderModeServiceV1;
  readonly 'onegraph-service': OneGraphServiceV1;
  readonly 'onegraph-query-service': OneGraphQueryServiceV1;
  readonly 'audi-number-formatter-service': NumberFormatterService;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'locale-service': '^1.0.0',
      'audi-content-service': '^1.0.0',
      'layer-manager': '^2.7.2',
      'onegraph-service': '^1.0.0',
      'onegraph-query-service': '^1.0.0',
      'audi-number-formatter-service': '^1.0.0',
    },
    externals: {
      '@audi/audi-ui-react': '^3.4.1',
    },
  },
  optionalDependencies: {
    featureServices: {
      'dbad:audi-vue-formatter-service': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      'audi-current-carline-service': '^1.0.0',
      'audi-video-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({
    featureServices,
    featureAppId,
    featureAppName,
  }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const state = true;
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');
    const contentService = featureServices['audi-content-service'];
    const renderModeService = featureServices['audi-render-mode-service'];
    const videoService = featureServices['audi-video-service'];
    const localeService = featureServices['locale-service'];
    const footnoteReferenceServiceScopeManager = featureServices['audi-footnote-reference-service'];
    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();
    const layerManager = featureServices['layer-manager'];
    const oneGraphService = featureServices['onegraph-service'];
    const numberFormatterService = featureServices['audi-number-formatter-service'];

    const { OneGraphProvider } = oneGraphService;
    const trackingService = !isTrackingDisabled()
      ? (featureServices['audi-tracking-service'] as TrackingServiceV2)
      : undefined;
    const serverRenderedContent = !featureServices['s2:async-ssr-manager']
      ? document.querySelector(`feature-app[id="${featureAppName}"]`)?.innerHTML
      : '';
    if (trackingService) {
      trackingService.featureAppName = __FEATURE_APP_NAME__;
    }

    return {
      render: () => {
        return state ? (
          <ContentContextProvider contentService={contentService}>
            <OneGraphProvider featureServices={featureServices}>
              <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
                <ContextProvider
                  featureAppEnv={{ featureAppId, featureAppName }}
                  localeService={localeService}
                  loggerService={loggerService}
                  trackingService={trackingService}
                  videoService={videoService}
                  layerManager={layerManager}
                  oneGraphService={oneGraphService}
                  numberFormatterService={numberFormatterService}
                >
                  <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
                    <UniversalEditorProvider
                      contentService={contentService}
                      renderModeService={renderModeService}
                    >
                      <App />
                    </UniversalEditorProvider>
                  </div>
                </ContextProvider>
              </FootnoteContextProvider>
            </OneGraphProvider>
          </ContentContextProvider>
        ) : (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: serverRenderedContent || '' }} />
        );
      },
    };
  },
};

export default featureAppDefinition;
