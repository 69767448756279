import { ContentFragment } from '@oneaudi/falcon-tools';

export const FA_LOCATOR_ID = `fa-editorial-stage`;
export const FA_LOCATOR = `[data-test-id=${FA_LOCATOR_ID}]`;

export enum Theme {
  Dark = 'Dark',
  Light = 'Light',
}

export enum CTAsTheme {
  Dark = 'Dark',
  Light = 'Light',
  Media = 'Media',
}

export enum AssetTypeEnum {
  Video = 'video',
  Image = 'image',
}

export interface ImageProps {
  image: AssetBrowserProps;
  imageAlt: string;
  assetDisclaimer: string;
}

export interface ImagesProps {
  imageLandscapeScreen: ImageProps;
  imagePortraitScreen: ImageProps;
}

export interface MediaProps {
  images?: ImagesProps;
  videos?: VideosProps;
}

export interface VideosProps {
  videoLandscapeScreen: VideoProps;
  videoPortraitScreen: VideoProps;
}

export interface ButtonProp {
  linkUrl: string;
  linkTarget: string;
  linkText: string;
}

export interface FeatureAppMeta {
  id: string;
}

export interface AssetBrowserProps {
  assetValue: string;
}

export interface VideoProps {
  assetDisclaimer: string;
  video: AssetBrowserProps;
  poster?: AssetBrowserProps;
  videoAlt: string;
  autoplay: boolean;
  mute: boolean;
  loop: boolean;
}

export interface MediaAsset {
  assetType: AssetTypeEnum;
  alt?: string;
  landscape?: {
    assetValue: string;
  };
  portrait: {
    assetValue: string;
  };
}

export interface AssetTypeImage extends MediaAsset {
  assetType: AssetTypeEnum.Image;
}

export interface AssetTypeVideo extends MediaAsset {
  assetType: AssetTypeEnum.Video;
  poster?: {
    assetValue: string;
  };
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  preserveVideoWidth?: boolean;
  aspectRatio?: '16:9' | '1:1';
}

export type AssetType = AssetTypeImage | AssetTypeVideo;
export enum Mode {
  LARGE = 'Large',
  SMALL = 'Small',
}

export enum ContentPosition {
  TOP_LEFT = 'TopLeft',
  TOP_RIGHT = 'TopRight',
  BOTTOM_LEFT = 'BottomLeft',
  CENTER = 'Center',
}

export interface AppearanceProps {
  theme: Theme;
  backgroundDisclaimersCTAs: CTAsTheme;
  enableShader: boolean;
  contentPosition: ContentPosition;
  mode: Mode;
}
export type HeadingTag = 'h1' | 'h2' | 'h3';

export interface TexContentProps {
  headline?: string;
  headingTag?: HeadingTag;
  subline?: string;
  primaryButton?: ButtonProp;
  secondaryButton?: ButtonProp;
}

export type OpenInTabOrLayer = 'page' | 'tab' | 'layer' | 'chatbot';

export interface CtaButton {
  label: string;
  url: string;
  ariaLabel?: string;
  openInTabOrLayer: OpenInTabOrLayer;
  chatBotId?: string;
}

export interface StagePropsFACE {
  __type?: 'editor.json';
  asset: AssetType;
  appearance: AppearanceProps;
  text: TexContentProps;
  buttons: CtaButton[];
  consumptionAndEmissions?: string[];
  disclaimers?: string[];
}

export interface StageProps {
  asset: AssetType;
  appearance: AppearanceProps;
  text: TexContentProps;
  buttons: CtaButton[];
  consumptionAndEmissions?: string[];
  disclaimers?: string[];
}

export interface FalconContent extends Partial<ContentFragment> {
  __type: 'aem-headless';
  fields: {
    appearanceTheme: Theme;
    appearanceBackgroundDisclaimersCTAs: CTAsTheme;
    appearanceEnableShader: boolean;
    appearanceContentPosition: ContentPosition;
    appearanceMode: Mode;
    asset: Partial<ContentFragment> & {
      title: string;
      description: string;
      id: string;
      path: string;
      contentPath: string;
      created: string;
      status: string;
      fields: {
        assetType: AssetTypeEnum;
        assetLandscape?: {
          path: string;
          contentPath: string;
        };
        assetPortrait?: {
          path: string;
          contentPath: string;
        };
        assetAlt?: string;
        assetPoster?: {
          path: string;
          contentPath: string;
        };
        assetAutoPlay?: boolean;
        assetLoop?: boolean;
        assetMuted?: boolean;
        assetControls?: boolean;
        assetPreserveVideoWidth?: boolean;
      };
    };
    textHeadline: string;
    textHeadingTag: HeadingTag;
    textSubline: string;
    firstButtonLabel: string;
    firstButtonUrl: string;
    firstButtonAriaLabel: string;
    firstButtonOpenInTabOrLayer: OpenInTabOrLayer;
    firstChatBotId?: string;
    secondButtonLabel: string;
    secondButtonUrl: string;
    secondButtonAriaLabel: string;
    secondButtonOpenInTabOrLayer: OpenInTabOrLayer;
    secondChatBotId?: string;
    consumptionAndEmissions: string[];
    disclaimers: string[];
  };
}
